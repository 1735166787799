import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Typography, Divider, Stack, Button, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel, Collapse, Alert } from '@mui/material';
import axios from 'axios';

const SaveModal = ({ open, handleClose, content, kw, id }) => {
    const [items, setItems] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [newFolder, setNewFolder] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    const response = await axios.get('/api/folder', config);
                    setItems(response.data);
                    setNewFolder(false);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
                setError('Error loading folders')
            }
        }
        if (newFolder)
            fetchData();
    }, [newFolder]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        //console.log('Selected value:', value)
        const folder = items.find(item => item._id === value);
        //console.log(folder)
        setSelectedFolder(folder);
    };

    const saveToFolder = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                //get every article in that folder then append the new article
                let articles = selectedFolder.articles || [];
                let histories = selectedFolder.histories || [];
                const {data} = await axios.get(`/api/history/history-or-article/${id}`, config);
                if (data.type === 'article') {
                    articles.push(id);
                } else {
                    histories.push(id);
                }
                for (const historyId of histories) {
                    let historyRes = '';
                    let history = historyId;
                    if (!historyId._id) {
                        historyRes = await axios.get(`/api/history/get/${historyId}`, config);
                        history = historyRes.data;
                    }
                        
                    if (history) {
                        const historyArticle = {
                            _id: history._id,
                            content: history.history.split('*-*')[0],
                            user: history.user,
                        };
                        articles.push(historyArticle);
                    }
                }
                await axios.put(`/api/folder/${selectedValue}`, { name: selectedFolder.name, articles: articles }, config);
                //await axios.put(`/api/folder/${selectedValue}`, { id: selectedValue, name: selectedFolder.name, articles: articles, histories: histories}, config);
                handleClose(); 
            }
        } catch (error) {
            console.error('Error saving to folder:', error);
            setError('Error saving to folder');
        }
    };

    const saveToNewFolder = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                await axios.post('/api/folder', { name: name, articles: [id] }, config);
                setName('');
                setNewFolder(true);
                handleClose(); 
            }
        } catch (error) {
            console.error('Error saving to new folder:', error);
            setError('Error saving to new folder');
        }
    };

    //useEffect for error
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError('');
            }, 5000);
        }
    }, [error]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            maxHeight='80vh'
        >
            <Stack maxWidth={500} backgroundColor="white" border="1px solid #000"
                justifyContent="center"
                sx={{ boxShadow: 2, p: 3, pt: 2, mx: 10, borderRadius: 2 , overflowY: 'auto'}}>
                <Collapse in={error.length > 0}>
                    <Alert severity="error" onClose={() => setError('')}>{error}</Alert>
                </Collapse>
                {items?.length > 0 &&
                    <>
                        <Typography fontWeight="bold" textAlign="left" fontSize={20} mb={2}>Save to Existing Folder</Typography>
                        <Typography fontWeight="bold" textAlign="left">Folders</Typography>
                        <FormControl component="fieldset" sx={{maxHeight: '30vh', overflowY: 'auto'}}>
                            <RadioGroup
                                aria-label="items"
                                name="items"
                                value={selectedValue}
                                onChange={handleChange}
                                row={false}
                            >
                                {items.map(item => (
                                    <FormControlLabel
                                        key={item._id}
                                        value={item._id}
                                        control={<Radio />}
                                        label={item.name}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        <Button variant="contained" sx={{ my: 2 }} onClick={saveToFolder}>Save</Button>
                        <Divider sx={{ mt: 1, mb: 2 }}>OR</Divider>
                    </>
                }
                <Typography fontWeight="bold" textAlign="left" fontSize={20} mb={2}>Save to New Folder</Typography>
                <TextField label="Folder Name" variant="outlined" size="small" onChange={(e) => setName(e.target.value)} value={name} />
                <Button variant="outlined" sx={{ mt: 2 }} onClick={saveToNewFolder} disabled={name.length === 0}>Save to New Folder</Button>
            </Stack>
        </Modal>
    );
}

export default SaveModal;
