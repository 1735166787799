import React from 'react';
import { Avatar, Stack, Tooltip } from '@mui/material';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
    LinkedinShareButton,
    LinkedinIcon,
    EmailShareButton,
    EmailIcon,
  } from "react-share";

const title = "Check out this article on Piearm.ai!";

const ShareIcon = ({id, kw}) => {

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`https://piearm.ai/share/${id}`);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <Stack direction="row" spacing={2} alignItems="top" mt={1.5}>
            <Tooltip title="Facebook" placement="top" arrow={true}>
                <FacebookShareButton url={`https://piearm.ai/share/${id}`} quote={title}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
            </Tooltip>

            <Tooltip title="Twitter" placement="top" arrow={true}>
                <TwitterShareButton url={`https://piearm.ai/share/${id}`} title={title}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
            </Tooltip>

            {/*<Tooltip title="Threads" placement="top" arrow={true}>
                <Box component={ReactRouterLink} to={`https://piearm.ai/share/${id}`} target="_blank">
                    <img src={`${process.env.PUBLIC_URL}/threads.webp`} alt="Threads Share" width="31px" height="auto" />
                </Box>
        </Tooltip>*/}

            <Tooltip title="WhatsApp" placement="top" arrow={true}>
                <WhatsappShareButton url={`https://piearm.ai/share/${id}`} title={title} separator=":: ">
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
            </Tooltip>

            <Tooltip title="Pinterest" placement="top" arrow={true}>
                <PinterestShareButton url={`https://piearm.ai/share/${id}`} media={`https://piearm.ai/share/${id}`} description={title}>
                    <PinterestIcon size={32} round={true} />
                </PinterestShareButton>
            </Tooltip>

            <Tooltip title="Reddit" placement="top" arrow={true}>
                <RedditShareButton url={`https://piearm.ai/share/${id}`} title={title}>
                    <RedditIcon size={32} round={true} />
                </RedditShareButton>
            </Tooltip>

            <Tooltip title="LinkedIn" placement="top" arrow={true}>
                <LinkedinShareButton url={`https://piearm.ai/share/${id}`}>
                    <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
            </Tooltip>

            <Tooltip title="Email" placement="top" arrow={true}>
                <EmailShareButton url={`https://piearm.ai/share/${id}`} subject={title} body={`Check out this article on ${kw}!\n\n`}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </Tooltip>

            <Tooltip title="Copy" placement="top" arrow={true}>
                <Avatar sx={{ width: 31, height: 31, ":hover": {cursor: "pointer"} }} onClick={copyToClipboard}>
                    <AddLinkRoundedIcon sx={{fontSize: "20px"}}/>
                </Avatar>
            </Tooltip>
        </Stack>
    );
}

export default ShareIcon;