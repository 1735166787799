import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Stack';
import { useMediaQuery } from '@mui/material';
import LoadingSpinner from '../common/LoadingSpinner';

function BlogScreen() {
  const mobile = useMediaQuery("(max-width: 1050px)");
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 100) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 100) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://blog.piearm.ai/wp-json/wp/v2/posts');
        const data = await response.json();
        const postsWithMediaPromises = data.map(async post => {
          const mediaResponse = await fetch(`https://blog.piearm.ai/wp-json/wp/v2/media/${post.featured_media}`);
          const media = await mediaResponse.json();
          return {
            ...post,
            featuredImageUrl: media.source_url,
          };
        });
        const postsWithMedia = await Promise.all(postsWithMediaPromises);
        setPosts(postsWithMedia);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    
    fetchData();
  }, []);

  if (loading) {
    return <Box p={30}><LoadingSpinner/></Box>;  // you can replace this with your own custom loader or a skeleton screen
  }

  function removeNonLettersAndSpaces(inputString) {
    return inputString.replace(/[^a-zA-Z\s]/g, '');
  }

  return (
    <Container>
      <iframe 
          src="https://blog.piearm.ai/" 
          title="PIEARM.AI Blog" 
          width="100%" 
          
          style={{ border: 'none', height:"98vh"  }}
      />
    </Container>
  );
}

export default BlogScreen;
