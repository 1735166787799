import React, {useEffect} from 'react' ;
import { Box, Button} from '@mui/material';
import './style.css';
import { useMediaQuery} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomeScreen = ({mode}) => { 
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem("authToken");
    const mobile = useMediaQuery("(max-width: 800px)");

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = "https://fast.wistia.com/embed/medias/1egs2iiudz.jsonp";
        script1.async = true;
    
        const script2 = document.createElement('script');
        script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
        script2.async = true;
    
        document.body.appendChild(script1);
        document.body.appendChild(script2);
    
        // Clean up the scripts on unmount
        return () => {
          document.body.removeChild(script1);
          document.body.removeChild(script2);
        };
      }, []);

    return (
        <Box sx={{fontFamily: "'Montserrat', serif;", letterSpacing: '0.5px'}}>
            <div id={mode === 'light' ? "hero_top" : ""} class="piearm-section" 
            style={{backgroundColor: mode === 'light' ? 'none' : "#202436", backgroundImage: mode === 'light' ? `url('https://m16marketing.com/wp-content/uploads/2023/03/piearm-hero.jpg')` : `none`}}>
                <div class="body-container">
                    <div class="hero-left hero-column">
                        <div class="inner-wrap">
                        <div class="hero-heading">
                            <h3>AI Marketing Tools</h3>
                            <h2>Work Smarter +</h2>
                            <div class="hero-subhead"><span class="gradient-item">30x Faster</span> with PIEARM.AI</div>
                        </div>
                        
                        <div class="hero-content">
                            <p>Minimize how you plan, implement, execute, analyze, report and measure marketing tasks.</p>
                        </div>
                        <Button onClick={loggedIn ? ()=> navigate("/dashboard") : () => navigate("/register")} variant="contained"
                        sx={{textTransform: 'none', fontSize: 17, width: 200, py:1.4, fontWeight: 700, letterSpacing: 0.5}}>
                            Maximize Results
                        </Button>
                        </div>
                    </div>

                    <div class="hero-right hero-column">
                    <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                        <div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
                            <div className="wistia_embed wistia_async_1egs2iiudz seo=true videoFoam=true" style={{ height: '100%', position: 'relative', width: '100%' }}>
                            <div className="wistia_swatch" style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
                                <img src="https://fast.wistia.com/embed/medias/1egs2iiudz/swatch" style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true" onLoad={(e) => { e.target.parentNode.style.opacity = 1; }} />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="process" class="piearm-section" style={{backgroundColor: mode === 'dark' ? "#293047" : "#fff", marginBottom: !mobile && "50px"  }}>
                <div class="container" >
                    <h2 class="section-title">How It Works</h2>
                    <div class="inner-three-column">
                        <div class={mode === 'light' ? "icon-column" : "icon-column-dark" }>
                            <span class={mode === 'light' ? "step-item" : "step-item-dark" }>1</span>
                            
                            <h3 class={mode === 'light' ? "icon-column-title" : "icon-column-title-dark" }>Go to Dashboard</h3>
                            <div class="icon-column-content"><p>Choose tools from keyword research to digital strategy to SEO copywriting, ads, and social media planning.</p></div>
                        </div>
                        
                        <div class={mode === 'light' ? "icon-column" : "icon-column-dark" }>
                            <span class={mode === 'light' ? "step-item" : "step-item-dark" }>2</span>
                            
                            <h3 class={mode === 'light' ? "icon-column-title" : "icon-column-title-dark" }>Add the Details</h3>
                            <div class="icon-column-content"><p>Provide the relevant information you need in the fields provided so PIEARM.AI can capture the context.</p></div>
                        </div>
                        
                        <div class={mode === 'light' ? "icon-column" : "icon-column-dark" }>
                            <span class={mode === 'light' ? "step-item" : "step-item-dark" }>3</span>
                            
                            <h3 class={mode === 'light' ? "icon-column-title" : "icon-column-title-dark" }>Click Generate</h3>
                            <div class="icon-column-content"><p>Within seconds your request is analyzed, processed, generated and can be extended and edited as needed.</p></div>
                        </div>
                    </div> 

                    <Box display="flex" justifyContent="center">
                    <Button onClick={loggedIn ? ()=> navigate("/dashboard") : () => navigate("/register")} variant="contained"
                        sx={{textTransform: 'none', fontSize: 17, width: 230, py:1.4, fontWeight: 700, letterSpacing: 0.5}}>
                            Get Your Free Access
                    </Button>
                    </Box>
                </div>
            </div>
        </Box>
        
    )
}

export default HomeScreen;