import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, InputLabel, MenuItem, FormControl, Stack,
    Select, IconButton, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Chip, OutlinedInput } from '@mui/material';
import { convert } from 'html-to-text';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link as ReactRouterLink } from 'react-router-dom';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const SavedArticleScreen = () => {
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [articles, setArticles] = useState([]);
    const [allArticles, setAllArticles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [selectedArticles, setSelectedArticles] = React.useState([]);
    const [edit, setEdit] = useState(false)

    const handleArticlesChange = (event) => {
        const {
        target: { value },
        } = event;
        setSelectedArticles(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
        console.log(typeof value === 'string' ? value.split(',') : value)
    };
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    const response = await axios.get('/api/folder', config);
                    setFolders(response.data);
                    const {data} = await axios.get('/api/auth/get-articles', config);
                    const articleList = []
                    for (const article of data) {
                        if (article?._id)
                            articleList.push(article);
                    }
                    //console.log(articleList);
                    const res = await axios.get(`/api/history/get-histories/chat`, config);

                    for (const history of res.data) {
                        const historyArticle = {
                            _id: history._id,
                            content: history.history.split('*-*')[0],
                            user: history.user,
                        };
                        articleList.push(historyArticle);
                    }
                    setAllArticles(articleList);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchFolders();
    }, []);

    useEffect(() => {
        const fetchArticlesandHistories = async () => {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    const response = selectedFolder ? await axios.get(`/api/folder/${selectedFolder}`, config) : await axios.get('/api/article', config);
                    const res = await axios.get(`/api/history/get-histories/chat`, config);
                    if (selectedFolder === "") {
                        const data = response.data;
                        
                        for (const history of res.data) {
                            const historyArticle = {
                                _id: history._id,
                                content: history.history.split('*-*')[0],
                                user: history.user,
                            };
                            data.push(historyArticle);
                        }
                        setArticles(data);
                    } else {
                        //console.log(response.data.articles)
                        let articleArray = response.data.articles;
                        //console.log(response.data.histories)
                        //for each history in the response, add it to the articleArray
                        for (const historyId of response.data.histories) {
                            //console.log(historyId)
                            const historyRes = await axios.get(`/api/history/get/${historyId}`, config);
                            const history = historyRes.data;
                            if (history) {
                                const historyArticle = {
                                    _id: history._id,
                                    content: history.history.split('*-*')[0],
                                    user: history.user,
                                };
                                articleArray.push(historyArticle);
                            }
                        }
                        setArticles(articleArray);
                    }
                    //console.log('folder', selectedFolder, response.data)
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchArticlesandHistories();
    }, [selectedFolder, open]);

    const reloadArticles = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const response = selectedFolder ? await axios.get(`/api/folder/${selectedFolder}`, config) : await axios.get('/api/article', config);
                const res = await axios.get(`/api/history/get-histories/chat`, config);
                if (selectedFolder === "") {
                    const data = response.data;
                    for (const history of res.data) {
                        const historyArticle = {
                            _id: history._id,
                            content: history.history.split('*-*')[0],
                            user: history.user,
                        };
                        data.push(historyArticle);
                    }
                    setArticles(data);
                } else {
                    //console.log(response.data.articles)
                    let articleArray = response.data.articles;
                    for (const historyId of response.data.histories) {
                        const historyRes = await axios.get(`/api/history/get/${historyId}`, config);
                        const history = historyRes.data;
                        if (history) {
                            const historyArticle = {
                                _id: history._id,
                                content: history.history.split('*-*')[0],
                                user: history.user,
                            };
                            articleArray.push(historyArticle);
                        }
                    }
                    setArticles(articleArray);
                }
                //console.log('folder', selectedFolder, response.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleCreateFolder = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const response = await axios.post('/api/folder', { name: newFolderName, articles: selectedArticles }, config);
                setFolders([...folders, response.data]);
                setNewFolderName("");
                setOpen(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateFolder = async () => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const response = await axios.put(`/api/folder/${selectedFolder}`, { name: newFolderName, articles: selectedArticles }, config);
                setFolders(folders.map(folder => folder._id === selectedFolder ? response.data : folder));
                setSelectedArticles([]);
                setNewFolderName("");
                setOpen(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteArticle = async (id) => {
        try {
            setLoading(true);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                if (selectedFolder === "") 
                    await axios.delete(`/api/article/${id}`, config);
                else
                    await axios.delete(`/api/folder/${selectedFolder}/${id}`, config);
                setArticles(articles.filter(article => article._id !== id));
                setAllArticles(allArticles.filter(article => article._id !== id));
                setLoading(false);
                navigate('/saves');
            } else {
                setLoading(false);
                setError("You are not authorized to use this feature. Please login.");
            }
        } catch (err) {
            setLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    };

    const deleteFolderArticle = async (articleId) => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                //console.log('old', articles)
                const newFolderArticles = articles.filter(article => article._id !== articleId);
               // console.log('new', newFolderArticles)
                //get folder name from selectedFolder
                const folder = folders.find(folder => folder._id === selectedFolder);
                await axios.put(`/api/folder/${selectedFolder}`, { name: folder.name, articles: newFolderArticles }, config);
                console.log('deleted article from folder')
                reloadArticles();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const deleteFolder = async (id) => {
        try {
            setLoading(true);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.delete(`/api/folder/${id}`, config);
                setFolders(folders.filter(folder => folder._id !== id));
                setLoading(false);
                navigate('/saves');
            } else {
                setLoading(false);
                setError("You are not authorized to use this feature. Please login.");
            }
        } catch (err) {
            setLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    }

    //usEffect to set folderArticles to the articles in the selected folder
    useEffect(() => {
        if (selectedFolder !== "") {
            const fetchFolderArticles = async () => {
                try {
                    const token = await axios.get("/api/auth/refresh-token");
                    if (token.data && token.data !== "Unauthorized") {
                        const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                        const response = await axios.get(`/api/folder/${selectedFolder}`, config);
                    }
                } catch (error) {
                    console.error(error);
                }
            };
            fetchFolderArticles();
        }
    }, [selectedFolder])

    const handleEditClick = (folder) => {
        setOpen(true);
        setEdit(true);
        setNewFolderName(folder.name);
        setSelectedArticles(folder.articles.map(article => article._id));
    };

    const createTitle = (content) => {
        return content.split(' ').slice(0, 6).join('-');
    }

    //useEffect for articles
    /*useEffect(() => {
        if (articles)
            console.log(articles) ;
    }, [articles]);*/

    return (
        <Box width={isNotMobile ? "50%" : "90%"} p="2rem" m="2rem auto" mt={isNotMobile ? 18 : 14} mb={isNotMobile ? "40vh" : 30} borderRadius={5} backgroundColor={theme.palette.background.alt} sx={{ boxShadow: 5 }}>
            <Collapse in={error}>
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
            </Collapse>
            <Typography variant="h4" fontWeight="bold" mb={3}>Saved Content</Typography>
            <FormControl fullWidth>
                <InputLabel id="folder-simple-select-label">Folder</InputLabel>
                <Select
                    labelId="folder-simple-select-label"
                    id="folder-simple-select"
                    value={selectedFolder}
                    label="Folder"
                    onChange={(event) => setSelectedFolder(event.target.value)}
                >
                    <MenuItem value=""><em>All</em></MenuItem>
                    {folders.map(folder => (
                    <MenuItem key={folder._id} value={folder._id}>
                        {folder.name}
                        <IconButton
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                handleEditClick(folder);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </MenuItem>
                        
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="article-simple-select-label">Content</InputLabel>
                <Select
                    labelId="article-simple-select-label"
                    id="article-simple-select"
                    value={selectedArticle ? selectedArticle._id : ""}
                    label="Content"
                    onChange={(event) => setSelectedArticle(articles.find(article => article._id === event.target.value))}
                >
                    {articles?.map(article => (
                        <MenuItem key={article._id} value={article._id} component={ReactRouterLink} to={article.content ? `/share/${article._id}/${createTitle(convert(article.content))}` : `/share/${article._id}/${convert(article.history).split('*-*')[0].substring(0, 20)}`}>
                            {article.content ? ( isNotMobile ? convert(article.content).substring(0, 50)+'...' : convert(article.content).substring(0, 22)+'...') :
                            article.history}
                            <IconButton
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    selectedFolder ? deleteFolderArticle(article._id) : deleteArticle(article._id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" size="large" fullWidth={true} onClick={() => setOpen(true)} sx={{ mt: 2 }}>Create New Folder</Button>
            {selectedFolder !== "" && selectedFolder !== "All" && <Button variant="outlined" size="large" fullWidth={true} onClick={() => handleEditClick(folders.find(folder => folder._id === selectedFolder))} sx={{ mt: 2 }}>Edit Folder</Button>}
            <Dialog open={open} onClose={() => setOpen(false)}>
                {edit ? 
                    <DialogTitle>Edit Folder</DialogTitle>
                    : 
                    <DialogTitle>Create New Folder</DialogTitle>
                }
                <DialogContent>
                    <Stack alignItems="center" spacing={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Folder Name"
                        type="text"
                        fullWidth
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                    />
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Content</InputLabel>
                        <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedArticles}
                        onChange={handleArticlesChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Content" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value, index) => (
                                <Chip key={value} label={convert(allArticles.find(item => item._id === value)?.content)} />
                            ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        >
                        {allArticles.map((article) => (
                            <MenuItem
                            key={article?._id}
                            value={article?._id}
                            style={getStyles(article?.content, selectedArticles, theme)}
                            >
                            {isNotMobile ? convert(article?.content).substring(0, 30) : convert(article?.content).substring(0, 22)}...
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setOpen(false); setSelectedArticles([]); setNewFolderName('')}} color="primary">
                        Cancel
                    </Button>
                    {edit ? 
                    <Button onClick={handleUpdateFolder} color="primary">
                        Edit
                    </Button>
                    : 
                    <Button onClick={handleCreateFolder} color="primary">
                        Create
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SavedArticleScreen;
