import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Card, Collapse, Alert, TextField, Button, Divider,
    Tooltip, InputAdornment, ClickAwayListener, IconButton, Stack, CircularProgress} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ThemedButton from '../common/ThemedButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { SocketContext } from '../../SocketContext';
//import LangSelect from '../common/LangSelect';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ShareModal from '../common/ShareModal';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import HearingIcon from '@mui/icons-material/Hearing';
import SaveIcon from '@mui/icons-material/Save';
import Tone from '../common/Tone';
import MicIcon from '@mui/icons-material/Mic';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { htmlToText } from 'html-to-text';
import SaveModal from '../common/SaveModal';
import LoadingSpinner from '../common/LoadingSpinner';

const product_title = "Describe your product or service."
//const key_feats_title = "Aspects of the product to highlight."
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center"
    },
    /*[`& .${tooltipClasses.arrow}`]: {
        color: "#f5f5f5",
        boxShadow: 1,
    },*/
}));

const ProductDescriptionScreen = () => { 
    const socket = React.useContext(SocketContext);
    const copyRef = useRef(null);
    const theme = useTheme();
    const [wordCount, setWordCount] = useState(0);
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    //const navigate = useNavigate();
    const [finished, setFinished] = useState(false);
    //const [keyFeatures, setKeyFeatures] = useState("");
    const [error, setError] = useState("");
    const [generated, setGenerated] = useState("");
    const [open, setOpen] = useState(false);
    const [productOpen, setProductOpen] = useState(false);
    const [language, setLanguage] = useState("English");
    const [shareOpen, setShareOpen] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [htmlContent, setHtmlContent] = useState('');
    const [btnText, setBtnText] = useState("Generate");
    const [audioUrl, setAudioUrl] = useState(null);
    const [tone, setTone] = useState("professional");
    const [product, setProduct] = useState("");
    const [saveOpen, setSaveOpen] = useState(false);
    const handleSaveClose = () => setSaveOpen(false);
    const handleShareClose = () => setShareOpen(false);

    const handleProductTooltipClose = () => {
        setProductOpen(false);
    };

    const handleProductTooltipOpen = () => {
        setProductOpen(true);
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Set up socket event listener only once, when the component mounts.
    useEffect(() => {
        const handleProductDescPipe = (data) => {
            setGenerated(prev => prev + data.message);
        };
  
        // Add event listener
        socket.on('productDescPipe', handleProductDescPipe);
    
        // Clean up the effect by removing the listener
        return () => socket.off('productDescPipe', handleProductDescPipe);
    }, []); // Empty dependency array to run only on mount and unmount

    const emailHandler = async (e) => { 
        e.preventDefault();

        try {
            setFinished(false);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                setGenerated("");
                setBtnText("Regenerate");
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.post("/api/auth/set-socket-id", { socketId: socket.id }, config);
                const { data } = await axios.post("/api/ai/product-desc", { product, tone, language }, config);
                if (data) {
                    setFinished(true);
                    console.log(data)
                }
                
            } else {
                setFinished(true);
                setError("You are not authorized to use this feature. Please login.");
                setTimeout(() => {
                    setError("");
                }, 5000);
                return;
            }
        } catch (err) {
            setFinished(true);
            console.log("An error occurred: ", err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }
    
    const handleCopyClick = async () => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const clipboardItem = new ClipboardItem({
                "text/plain": new Blob(
                    [quill.root.innerHTML],
                    { type: "text/plain" }
                ),
                "text/html": new Blob(
                    [quill.root.outerHTML],
                    { type: "text/html" }
                ),
            });
            
            navigator.clipboard.write([clipboardItem]);
        }
    };

    const quillRef = useRef(null);

    const handleInsertText = async () => {
        try {
            const htmlContent = copyRef.current.innerHTML;

            // Access the Quill instance
            const quill = quillRef.current.getEditor();
    
            // Use the clipboard module to insert HTML
            quill.clipboard.dangerouslyPasteHTML(htmlContent);
          } catch (err) {
            setError(`Failed to paste text: ${err.message}`);
            setTimeout(() => { setError(""); }, 3000);
          }
    };

    const handleShare = async () => {
        try {
            if (!htmlContent)
                handleInsertText();

            const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    let artId = '';
                    if (!articleId) {
                        const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : generated}, config)
                        if (data) {
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    } else {
                        //console.log(htmlContent)
                        const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : generated, id: articleId}, config)
                        if (data) {
                            //console.log(data)
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    }
                    await axios.post("/api/article/share", {id: artId}, config);
                    setShareOpen(true);
                }
        } catch (err) {
            setShareOpen(true);
            setError("You are not authorized to use this feature. Please login.");
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    const handleSave = async () => {
        try {
            if (!htmlContent)
                handleInsertText();

            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                let articleIdData;
                if (!articleId) {
                    const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : generated, public: false}, config)
                    if (data) {
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                } else {
                    //console.log(htmlContent)
                    const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : generated, id: articleId, public: false}, config)
                    if (data) {
                        //console.log(data)
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                }
                // save article to user
                await axios.post("/api/auth/save-article", {articleId: articleIdData}, config);
                setSaveOpen(true);
            }
        } catch (err) {
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    // Function to handle changes in the editor and update the state
    const handleEditorChange = (content, delta, source, editor) => {
        setHtmlContent(editor.getHTML());
        const text = editor.getText();
        if (text) {
            const count = text.trim().split(/\s+/).filter(word => word.length > 0).length;
            setWordCount(count);
        }
    };
    const [listenLoading, setListenLoading] = useState(false);

    const handleListen = async () => {
        try {
            setListenLoading(true);
            let listenText = htmlToText(htmlContent);
            listenText = listenText.replace(/\*/g, "-");
            const {data} = await axios.post("/api/tts", { text: listenText }, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',  // Set content type to JSON if you're sending JSON data
                },
            },
            );
            // Convert audioData (which is an ArrayBuffer) into a blob
            const audioBlob = new Blob([data], { type: "audio/wav" });
            
            // Convert the blob into an object URL
            setAudioUrl(window.URL.createObjectURL(audioBlob));
            setListenLoading(false);
            /*const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                await axios.post("/api/auth/set-socket-id", { socketId: socket.id }, config);
                await axios.post("/api/ai/speech", { content: generated }, config);
            } else {
                setError("You are not authorized to use this feature. Please login.");
                setTimeout(() => {
                    setError("");
                }, 5000);
                return;
            }*/
        } catch (err) {
            console.log(err.message);
            setListenLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    const addAudioElement = async (blob, field) => {
        const formData = new FormData();
        formData.append('audio', blob, 'audio.mp3');
        
        try {
            const response = await axios.post('/api/stt', formData);
            if (field === 'product')
                setProduct(response.data)
            /*else if (field === 'key features')
                setKeyFeatures(response.data)*/
        } catch (err) {
            console.error("There was an error uploading the audio:", err);
        }
    };

    const { startRecording, stopRecording, recordingBlob, isRecording} = useAudioRecorder();
    
    useEffect(() => {
        if (!recordingBlob) return;
        addAudioElement(recordingBlob, 'product')
    // recordingBlob will be present at this point after 'stopRecording' has been called
    }, [recordingBlob]);

    //usEffect for generated
    useEffect(() => {
        if (generated.length > 0) {
            setHtmlContent(generated);
            const quill = quillRef?.current?.getEditor();
            const delta = quill?.clipboard?.convert(generated);
            quill?.setContents(delta);
        }
    }, [generated]);

    return (
        <Box width={isNotMobile ? "52%" : "90%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "22vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={emailHandler}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center">Product/Service Description Generator</Typography>

                <Divider sx={{ mt:1, mb:2}}></Divider>

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500} mb={isNotMobile ? 1 : 0.5}>Describe your product or service</Typography> 
                
                <ClickAwayListener onClickAway={handleProductTooltipClose}>
                    <LightTooltip 
                    PopperProps={{
                    disablePortal: true,
                    }}
                    onClose={handleProductTooltipClose}
                    placement="top-end"
                    open={productOpen}
                    disableFocusListener
                    disableTouchListener
                    title={product_title}
                    >
                        <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1, fontSize: 16 }, mb:isNotMobile ? 4 : 2}} 
                        required size={isNotMobile ? "medium" : "small"} placeholder="Hair/Hair Styling" fullWidth value={product} 
                        onChange={(e) => {setProduct(e.target.value); handleProductTooltipClose();}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Stack direction="row" spacing={.5} alignItems="center">
                                    {!isRecording && <IconButton onClick={startRecording} 
                                    sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                        <MicIcon sx={{color: "background.alt", fontSize: 16}}/>
                                    </IconButton>
                                    }
                                    {isRecording && <IconButton onClick={stopRecording} 
                                    sx={{bgcolor: "primary.main", p: 0.3, "&:hover": {bgcolor: 'primary.dark'}}}>
                                        <StopRoundedIcon sx={{color: "background.alt", fontSize: 16}}/>
                                    </IconButton>
                                    }
                                    <IconButton onClick={handleProductTooltipOpen} onMouseEnter={handleProductTooltipOpen}>
                                        <HelpIcon  sx={{color: "primary.main"}}/>
                                    </IconButton>
                                </Stack>
                            </InputAdornment>
                            ),
                        }}
                        />
                    </LightTooltip>
                </ClickAwayListener>
                

                <Typography fontSize={isNotMobile ? 18 : 16} fontWeight={500}>Tone</Typography> 
                <Tone tone={tone} setTone={setTone}/>

                {/*<Typography variant={isNotMobile ? "h6" : "body2"} fontWeight={500}>Language</Typography> 
                <LangSelect language={language} setLanguage={setLanguage}/>*/}

                <ThemedButton w="100%" type="submit" text={btnText} fSize={isNotMobile ? 16 : 14} pad={isNotMobile ? 1.4 : 0.8} bool={tone.length === 0 || language.length === 0 || (!finished && generated.length !== 0)}/>
                
                <Divider sx={{my: isNotMobile ? 4 : 2}}></Divider>

            </form>

            { generated &&
            <Box width="100%">
                <Typography variant={isNotMobile ? "h4" : "h5"} fontWeight="bold" mb={2}> Report Mockup </Typography>
                <ReactQuill ref={quillRef}  theme="snow" onChange={handleEditorChange}></ReactQuill>
                <Button variant="outlined" onClick={handleCopyClick} startIcon={<ContentCopyRoundedIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2, mt :1}}>
                        Copy Text
                </Button>
                <Box border='1px solid grey' borderRadius={2} p={1} mb={4} mt={2}>
                    <Typography textAlign="center" color="black">Word count: {wordCount} </Typography>
                </Box>
                {finished && 
                <Stack direction={isNotMobile ? "row" : "column"} alignItems="center"spacing={2} mb={2}>
                    <Button variant="outlined" onClick={()=>handleListen()} startIcon={listenLoading ? <CircularProgress size={14}/> : <HearingIcon/>}
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}} disabled={listenLoading}>
                        Listen
                    </Button>
                    <Button variant="contained" startIcon={<SaveIcon/>} 
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                    onClick={()=>handleSave()}>
                        Save
                    </Button>
                    <Button variant="outlined" size="medium" onClick={handleShare}
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                            startIcon={ <ShareRoundedIcon sx={{color: "primary.main"}}/> }
                    >
                        Share
                    </Button>
                    <Button variant="contained" onClick={() => { window.scrollTo({top: 0, behavior: "smooth"});}}
                        sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, borderRadius: 1, width: 140}}
                        startIcon={ <KeyboardDoubleArrowUpRoundedIcon/> }
                    > 
                        Back to top
                    </Button>
                    <SaveModal
                        open={saveOpen}
                        content={htmlContent ? htmlContent : generated}
                        handleClose={handleSaveClose}
                        mobile={!isNotMobile}
                        id={articleId}
                    />
                    <ShareModal
                        open={shareOpen}
                        content={htmlContent ? htmlContent : generated}
                        handleClose={handleShareClose}
                        mobile={!isNotMobile}
                        id={articleId}
                        kw={product}
                    />
                </Stack>
                }
                {listenLoading && 
                    <Stack>
                        <LoadingSpinner text="Please wait a moment while your audio file processes."/>
                    </Stack>
                }
                {audioUrl && <audio controls src={audioUrl}></audio>}
            </Box>
            }
            
        </Box>
    )
}

export default ProductDescriptionScreen;