import React from 'react';
import '../screens/style.css'
import { useMediaQuery} from '@mui/material';

const Footer = () => { 
    const mobile = useMediaQuery("(max-width: 800px)");

    //get current year
    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
        { mobile ?
        <footer>
            <div className="container footer-container">
                <span className="copyright">© {year} - PIEARM.AI</span>
            </div>
        </footer>
        :
        <footer style={{ position: "fixed", bottom: 0, width: "100%" }}>
            <div className="container footer-container">
                <span className="copyright">© {year} - PIEARM.AI</span>
            </div>
        </footer>
        }
        </>
    )
}

export default Footer;