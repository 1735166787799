import React from 'react' ;
import { Box, Link, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ThemedButton from '../common/ThemedButton';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
//import Cookies from 'js-cookie';

const LoginScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const config = {
        headers: { "Content-Type": "application/json" },
    }

    const loginHandler = async (e) => { 
        e.preventDefault();

        try {
            const { data } = await axios.post("/api/auth/login", {email, password}, config);
            if (data.token.accessToken) {
                localStorage.setItem("authToken", true);
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${data.token.accessToken}`
                    },
                };
                const usr = await axios.get(`/api/auth/subscription`, config);
                if (usr.data.subscription) {
                    localStorage.setItem("sub", true);
                }
                let nav = "/dashboard";
                await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                navigate(nav);
                //window.location.reload();
            }
        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            }
        }
    }

    const [ user, setUser ] = useState("");

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token);},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post("/api/auth/google-login", {google_email, verified_email}, config);
                        if (data.token.accessToken) {
                            localStorage.setItem("authToken", true);
                            const config = {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${data.token.accessToken}`
                                },
                            };
                            const usr = await axios.get(`/api/auth/subscription`, config);
                            if (usr.data.subscription) {
                                localStorage.setItem("sub", true);
                            } else {
                                localStorage.removeItem("sub");
                            }
                            let nav = "/dashboard";
                            await axios.get("/api/auth/admin", config).then(res => {localStorage.setItem("admin", res.data.admin); nav="/admin" });
                            await axios.get("/api/auth/partner", config).then(res => {localStorage.setItem("partner", res.data.partner); nav="/partner" });
                            navigate(nav);
                            //window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                        } else {
                            setError(err.message);
                        }
                    });
            }
        },
        [ user ]
    );

    //useEffect for error
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }, [error]);

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 16 : 14}
            mb={isNotMobile ? 14 : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={loginHandler}>
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center">Sign In</Typography>
                <Button variant="outlined" size="large" fullWidth sx={{py: 1.3}}
                    startIcon={<GoogleIcon/>}
                    onClick={() => googleLogin()}
                >
                    Sign in with Google
                </Button>

                <Divider sx={{mt:3, mb:2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:2}} required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:4}} required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>
                { /* <Button fullWidth variant="contained" type="submit" size="large" sx={{color:"white", my:2}}>Log in</Button> */ }
                <ThemedButton w="100%" type="submit" text="Log in"/>

                <Divider sx={{my:4}}></Divider>
            </form>
            <Typography mt={2} textAlign="center">Don't have an account? <ReactRouterLink style={{color: theme.palette.primary.main, textDecoration: 'none'}} to="/register"><b style={{':hover': { textDecoration: 'underline' }}}>Sign up</b></ReactRouterLink></Typography>
            <Typography mt={2} textAlign="center"> <ReactRouterLink to="/forgot-password" style={{color:theme.palette.primary.main, ':hover': { textDecoration: 'underline' }}}>Forgot your password?</ReactRouterLink></Typography>

        </Box>
    )
}

export default LoginScreen;