import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, TextField, Button, Typography, Divider, Collapse, Alert } from '@mui/material';
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';
import './PricingScreen.css'
import Cookies from 'js-cookie';

const stripePromise = loadStripe(`pk_live_dJx216mCGQrA6Bg80UGVAfJN`);

const TestScreen = () => {
    const mobile = useMediaQuery("(max-width: 800px)");
    const [clientSecret, setClientSecret] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [proPrice, setProPrice] = useState(35);
    const [enterprisePrice, setEnterprisePrice] = useState(350);
    const [showCouponCard, setShowCouponCard] = useState(true);
    const [promoCode, setPromoCode] = useState("");
    const [error, setError] = useState("");
    const [perSub, setPerSub] = useState("/mo");
    const {id} = useParams();

    //useEffect for id
    useEffect(() => {
        if (id.includes("yearly")) {
            setProPrice(350);
            setEnterprisePrice(3500);
            setPerSub("/yr");
        }
    }, [id]);

    useEffect( () => {
        if (!showCouponCard) {
            // Create PaymentIntent as soon as the coupon card is dismissed
            const test = async () => {
                const token = await axios.get('/api/auth/refresh-token');
                if (token.data && token.data !== "Unauthorized") {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.data}`
                        },
                    };
                    
                    const data = await axios.post('/api/stripe/pay', {sub: id, promoCode: promoCode}, config);
                    setClientSecret(data.data.client_secret)
                    setCustomerId(data.data.customerId)
                    
                }
            }
            test();

            /*if (Cookies.get('ref')) {
                setEnterprisePrice(300);
                setProPrice(30);
            }*/
        }
    }, [showCouponCard]);

    const handleCouponSubmit = async () => {
        if (!promoCode) {
            setError("Please enter a coupon code")
            return;
        }
        //verify coupon code
        const token = await axios.get('/api/auth/refresh-token');
        if (token.data && token.data !== "Unauthorized") {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.data}`
                },
            };
            const data = await axios.post('/api/stripe/verify-promo', {promoCode: promoCode}, config);
            if (data.data) {
                if (id.includes("yearly")) {
                    setProPrice(300);
                    setEnterprisePrice(3000);
                } else {
                    setProPrice(30);
                    setEnterprisePrice(300);
                }
                setShowCouponCard(false);
            } else {
                //alert("Invalid Coupon Code")
                setError("Invalid Coupon Code")
            }
        }
        //setShowCouponCard(false);
    }

    //useEffect for error
    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError("");
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    const handleSkipCoupon = () => {
        setPromoCode("");
        setShowCouponCard(false);
    }

    const appearance = {
        theme: 'stripe',
        variables: {
            colorDanger: '#df1b41',
            fontFamily: 'Montserrat, sans-serif',
        },
    };

    const options = {
        clientSecret,
        appearance,
        fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap'
        }]
    };

    return (
        <>
        {showCouponCard ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Card sx={{ padding: 4, borderRadius: 3 }}>
                    <Collapse in={error.length > 0}>
                        <Alert severity="error" onClose={() => setError('')}>{error}</Alert>
                    </Collapse>
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
                            Enter Your Coupon Code
                        </Typography>
                        <TextField
                            label="Coupon Code"
                            variant="outlined"
                            fullWidth
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <Button variant="contained" color="primary" size={mobile ? "medium" : "large"} fullWidth onClick={handleCouponSubmit} sx={{ marginBottom: 1 }}>
                            Apply Coupon
                        </Button>
                        <Divider sx={{mt:3, mb:2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>
                        <Button variant="text" color="primary" fullWidth onClick={handleSkipCoupon}>
                            Continue without Promotion Code
                        </Button>
                    </CardContent>
                </Card>
            </Box>
        ) : (
            mobile ?  
                <Stack spacing= {4} sx={{ fontFamily: "Montserrat", fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', my: "12vh"}}>
                    <Box>
                        { id.includes("pro") ?
                        <>
                        <div className="price-heading" style={{fontSize: 50, padding: 0,}}>Pro Tier</div>
                        {promoCode.length > 0 && <div className="price-ammount" style={{padding: 0, lineHeight: 1}}><span className="price-figure" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>${proPrice*7/6}</span><span className="price-suffix" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>{perSub}</span></div>}
                        <div className="price-ammount" style={{padding: 0}}><span className="price-figure">${proPrice}</span><span className="price-suffix">{perSub}</span></div>
                        </>
                        :
                        <>
                        <div className="price-heading" style={{fontSize: 50, padding: 0,}}>Enterprise Tier</div>
                        {promoCode.length > 0 && <div className="price-ammount" style={{padding: 0, lineHeight: 1}}><span className="price-figure" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>${enterprisePrice*7/6}</span><span className="price-suffix" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>{perSub}</span></div>}
                        <div className="price-ammount" style={{padding: 0}}><span className="price-figure">${enterprisePrice}</span><span className="price-suffix">{perSub}</span></div>
                        </>
                        }
                    </Box>
                    <Box >
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm clientSecret={clientSecret} customerId={customerId}/>
                        </Elements>
                    )}
                    </Box>
                </Stack>
                :
                <Box sx={{ fontFamily: "Montserrat", fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'top'}}>
                    <Box sx={{width: "25%", mt: "32vh" , ml: 30}}>
                        { id.includes("pro") ?
                        <>
                        <div className="price-heading" style={{fontSize: 50, padding: 0,}}>Pro Tier</div>
                        {promoCode.length > 0 && <div className="price-ammount" style={{padding: 0, lineHeight: 1}}><span className="price-figure" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>${proPrice*7/6}</span><span className="price-suffix" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>{perSub}</span></div>}
                        <div className="price-ammount" style={{padding: 0}}><span className="price-figure">${proPrice}</span><span className="price-suffix">{perSub}</span></div>
                        </>
                        :
                        <>
                        <div className="price-heading" style={{fontSize: 50, padding: 0,}}>Enterprise Tier</div>
                        {promoCode.length > 0 && <div className="price-ammount" style={{padding: 0, lineHeight: 1}}><span className="price-figure" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>${enterprisePrice*7/6}</span><span className="price-suffix" style={{textDecoration: 'line-through', color: '#a9b3d6'}}>{perSub}</span></div>}
                        <div className="price-ammount" style={{padding: 0}}><span className="price-figure">${enterprisePrice}</span><span className="price-suffix">{perSub}</span></div>
                        </>
                        }
                    </Box>
                    <Box sx={{ width: '50%', margin: '0 auto', my: 20, mr: 30 }}>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm clientSecret={clientSecret} customerId={customerId} referenceCode={promoCode}/>
                        </Elements>
                    )}
                    </Box>
                </Box>
        )}
        </>
    );
};

export default TestScreen;
