import React, {useState} from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, } from '@mui/material';
import { useNavigate, useParams} from "react-router-dom";
import axios from 'axios';

const VerifyEmailScreen = () => { 
    const theme = useTheme();
    const navigate = useNavigate();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const {verifyToken}  = useParams();
    console.log(verifyToken)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const verifyEmailHandler = async () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/auth/verify-email/${verifyToken}`,
                config
            );

            setSuccess(data.data);
            navigate("/login")
        } catch (error) {
            setError(error.response.data.error);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    verifyEmailHandler();

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "2rem auto" : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Typography>Thank you for verifying your email!</Typography>

        </Box>
    )
}

export default VerifyEmailScreen;