import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Typography, CardContent, useMediaQuery, Card, Grid, Divider, Tooltip, Button } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import axios from 'axios';

const ItemType = 'CARD';

const defaultOrder = [
    ["Ask PIEARM", "Make PIEARM your chat assistant.", "ask-piearm"],
    ["Market Research", "Gather detailed information on your target market.", "market-research"],
    ["Keyword Research", "Generate SEO keyword research reports with ease.", "keyword-research"], 
    ["Digital Strategy", "Create a digital strategy for a brand, company, product, or service.", "digital-strategy"],
    ["Content Strategy", "Create a media strategy to grow your business.", "content-strategy"],
    ["Article Generator", "Create high-quality content that provides in-depth information on any topic that you desire.", "seo"],
    ["Press Release Generator", "Craft captivating press releases with ease.", "pr-copy"],
    ["Ad Copy Generator", "Create compelling ad content that drives results.", "ad-copy"],
    ["Social Media Plan Generator", "Receive a comprehensive social media strategy.", "social-media-plan"],
    ["Social Media Copy Generator", "Create compelling social media copy.", "social-media-copy"],
    ["Social Media Growth", "Grow your socials with tailored strategies.", "social-growth"],
    ["Marketing Email Copy Generator", "Craft conversion-driven emails effortlessly.", "marketing-email"],
    ["Newsletter Copy Generator", "Create content that keeps subscribers engaged.", "newsletter-copy"],
    ["Product or Service Copy Generator", "Create webpage descriptions that showcase your offerings.", "product-service-description"],
    ["Audio Transcription", "Transcribe audios into text.", "stt"],
    ["Funding Plan Generator", "Craft a comprehensive plan to raise capital.", "funding-plan"],
    ["Analytics and Reporting", "Custom Google Analytics KPIs integration and reports.", "enterprise"],
    ["AI Lead Generation", "Collects leads and visitor data.", "enterprise"],
];

const Dashboard = ({ mode }) => {
    const [cards, setCards] = useState(defaultOrder);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenResponse = await axios.get("/api/auth/refresh-token");
                const token = tokenResponse.data;

                if (token && token !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` } };
                    const dashboardResponse = await axios.get("/api/auth/dashboard", config);
                    const data = dashboardResponse.data;

                    if (data?.length === defaultOrder.length && Array.isArray(data)) {
                        setCards(data);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const updateData = async () => {
            try {
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    await axios.post("/api/auth/update-dashboard", { dashboard: cards }, config);
                }
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    }, [cards]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) => {
            const updatedCards = [...prevCards];
            const [draggedCard] = updatedCards.splice(dragIndex, 1);
            updatedCards.splice(hoverIndex, 0, draggedCard);
            return updatedCards;
        });
    }, []);

    const mobile = useMediaQuery("(max-width: 600px)");
    const lower = useMediaQuery("(max-width: 900px)");
    const upper = useMediaQuery("(max-width: 1300px)");

    const boxStyle = {
        flexGrow: 1,
        margin: 'auto',
        maxWidth: 1500,
        py: 15,
        px: "5vw",
        mb: "15vh"
    };

    const cardStyle = {
        height: 230,
        boxShadow: mode === 'light' ? "0 0 6px 3px #efefef" : "0 0 6px 3px #393f4a",
        border: mode === 'light' ? "1px solid #efefef" : "1px solid #393f4a",
        flexGrow: 1,
        flexBasis: 0,
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': { cursor: 'pointer' },
        bgcolor: mode === 'light' ? "white" : '#151920',
        borderRadius: 4,
    };

    const CardItem = ({ card, index, moveCard }) => {
        const ref = useRef(null);
        const [, drop] = useDrop({
            accept: ItemType,
            hover(item, monitor) {
                if (!ref.current) return;
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) return;

                const hoverBoundingRect = ref.current.getBoundingClientRect();
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset = monitor.getClientOffset();
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                moveCard(dragIndex, hoverIndex);
                item.index = hoverIndex;
            },
        });
        const [{ isDragging }, drag] = useDrag({
            type: ItemType,
            item: { type: ItemType, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });
        const opacity = isDragging ? 0 : 1;
        drag(drop(ref));
        return (
            <Grid ref={ref} item xs={12} sm={6} md={4} lg={3} style={{ opacity }} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Drag me!" placement="top" arrow>
                    <Card sx={{ ...cardStyle, position: 'relative' }}>
                        <Box component={ReactRouterLink} to={`/${card[2]}`} sx={{ color: "black" }}>
                            <div className="price-heading" style={{ fontSize: !lower && upper ? "20px" : (mobile ? "28px" : "24px"), margin: 12 }}>
                                {card[0]}
                            </div>
                            <CardContent sx={{ py: 1.2, px: 2 }}>
                                <Typography sx={{letterSpacing: '0.5px',}} color={mode === 'light' ? 'black' : 'white'} fontSize={!lower && upper ? 14 : (mobile ? 15 : 16)} textAlign="center">
                                    {card[1].includes('PIEARM') ? <span>Make <b>PIEARM</b> your chat assistant.</span> : card[1]}
                                </Typography>
                            </CardContent>
                            <Button
                                component={ReactRouterLink}
                                to={`/${card[2]}`}
                                variant="contained"
                                disableElevation
                                sx={{
                                    fontSize: 16,
                                    py: 1,
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    position: 'absolute',
                                    bottom: '12px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: 'calc(100% - 32px)',
                                }}
                            >
                                Access
                            </Button>
                        </Box>
                    </Card>
                </Tooltip>
            </Grid>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Box sx={boxStyle}>
                <Typography variant={lower ? (mobile ? "h3" : "h2") : "h1"} fontWeight={650} mb={1} textAlign="center">Marketing Tools</Typography>
                <Typography color="grey" fontSize={lower ? (mobile ? 14 : 16) : 18} mb={1} textAlign="center">Drag and drop tools to reorder your dashboard</Typography>
                <Divider sx={{ mb: 5 }} />
                <Grid container spacing={3} justifyContent="center">
                    {cards.map((card, index) => (
                        <CardItem key={index} index={index} card={card} moveCard={moveCard} />
                    ))}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                    <Button variant="outlined" onClick={() => setCards(defaultOrder)} size="large" fullWidth>Reset Order</Button>
                </Box>
            </Box>
        </DndProvider>
    );
};

export default Dashboard;
