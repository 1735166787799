import React from 'react';
import { FormControl,Select, MenuItem } from '@mui/material';
import axios from 'axios';

const Dropdown = ({plan, setPlan, margB, size, fontS, userId}) => {

  const handleChange = async (event) => {
    setPlan(event.target.value);
    if (userId) {
        const newPlan = event.target.value;
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data) {
            const config = {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}`},
            };
            await axios.put(`/api/auth/admin-change-plan/${userId}`, { plan: newPlan }, config);
            window.location.reload();
        }
    }
  };

  return (
    <FormControl fullWidth sx={{mb: margB}}>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        value={plan}
        onChange={handleChange}
        sx={{boxShadow: 2, fontSize: fontS ? fontS : 16}}
        size={size}
      >
        <MenuItem value="">None</MenuItem>
        <MenuItem value="pro">Pro</MenuItem>
        <MenuItem value="premium">Premium</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Dropdown;
