import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Card, Collapse, Alert, TextField, Button, Divider, 
    InputAdornment, Stack, CircularProgress, Chip} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ThemedButton from '../common/ThemedButton';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ContentPasteGoRoundedIcon from '@mui/icons-material/ContentPasteGoRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import ShareModal from '../common/ShareModal';
import { SocketContext } from '../../SocketContext';
import SaveIcon from '@mui/icons-material/Save';
import LoadingSpinner from '../common/LoadingSpinner';

const MAX_SIZE = 10 * 1024 * 1024; //10MB
const MAX_UPLOADS = 1;
const SttScreen = ({mode}) => { 
    const theme = useTheme();
    const socket = React.useContext(SocketContext);
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    //const navigate = useNavigate();
    const [finished, setFinished] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [error, setError] = useState("");
    const [generated, setGenerated] = useState("");
    //const [open, setOpen] = React.useState(false);
    const [shareOpen, setShareOpen] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [htmlContent, setHtmlContent] = useState('');
    const [btnText, setBtnText] = useState("Transcribe");
    const [audioUrl, setAudioUrl] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [ytUrl, setYtUrl] = useState("");
    const [ url, setUrl ] = useState("");
    const [fileList, setFileList] = useState([]);
    const handleShareClose = () => setShareOpen(false);

    const fileInputRef = useRef(null);
    const cardTextRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //useEffect for error
    useEffect(() => {
        if (error) {
            window.scrollTo(0, 0);
            //clear after five seconds
            const timeout = setTimeout(() => {
                setError("");
            }
            , 5000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    // Set up socket event listener only once, when the component mounts.
    useEffect(() => {
        const handleYtCopyPipe = (data) => {
            setGenerated(prev => prev + data.message);
            //console.log(data.message)
        };
  
        // Add event listener
        socket.on('ytPipe', handleYtCopyPipe);
    
        // Clean up the effect by removing the listener
        return () => socket.off('ytPipe', handleYtCopyPipe);
    }, []); // Empty dependency array to run only on mount and unmount

    const handleCopyClick = async () => {
        try {
            const textElement = cardTextRef.current;
            const range = document.createRange();
            range.selectNodeContents(textElement);
            await navigator.clipboard.writeText(range);
          } catch (err) {
            setError('Failed to copy text: ', err.message);
            setTimeout(() => { setError(""); }, 3000);
        }
    };

    const quillRef = useRef(null);

    const handleInsertText = () => {
        const quill = quillRef.current.getEditor();
        quill.insertText(0, generated.trimStart());
    };

    const handleShare = async () => {
        try {
            if (!htmlContent)
                handleInsertText();

            const token = await axios.get("/api/auth/refresh-token");
                if (token.data && token.data !== "Unauthorized") {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    let artId = '';
                    if (!articleId) {
                        const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : generated}, config)
                        if (data) {
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    } else {
                        //console.log(htmlContent)
                        const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : generated, id: articleId}, config)
                        if (data) {
                            //console.log(data)
                            artId = data._id;
                            setArticleId(data._id);
                        }
                    }
                    await axios.post("/api/article/share", {id: artId}, config);
                    setShareOpen(true);
                }
        } catch (err) {
            setShareOpen(true);
            setError("You are not authorized to use this feature. Please login.");
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    const handleSave = async () => {
        try {
            if (!htmlContent)
                handleInsertText();

            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                let articleIdData;
                if (!articleId) {
                    const {data} = await axios.post("/api/article", {content: htmlContent ? htmlContent : generated, public: false}, config)
                    if (data) {
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                } else {
                    //console.log(htmlContent)
                    const {data} = await axios.put(`/api/article`, {content: htmlContent ? htmlContent : generated, id: articleId, public: false}, config)
                    if (data) {
                        //console.log(data)
                        setArticleId(data._id);
                        articleIdData = data._id;
                    }
                }
                // save article to user
                await axios.post("/api/auth/save-article", {articleId: articleIdData}, config);
            }
        } catch (err) {
            setTimeout(() => {
                setError("");
            }
            , 5000);
        }
    };

    // Function to handle changes in the editor and update the state
    const handleEditorChange = (content, delta, source, editor) => {
        setHtmlContent(editor.getHTML());
    };
    
    const handleCardClick = () => {
        fileInputRef.current.click();
    };

    async function handleDrop(event) {
        event.preventDefault();
        const acceptedFileTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/plain'
        ];

        try {
            
            const files = Array.from(event.dataTransfer.files);
            const newFiles = [];
            const newFileNames = [];

            for (let file of files) {
                if (uploadedFiles.length >= MAX_UPLOADS)
                    break;

                if (uploadedFiles.includes(file.name)) {
                    alert('File already uploaded.');
                    break;
                }
    
                const fileType = file.type;
                if (!acceptedFileTypes.includes(fileType)) {
                    alert('Invalid file type. Only audio (MP3 or WAV) files are accepted.');
                    event.target.value = null; // Clear the input field
                    continue;
                }

                if (file.size > MAX_SIZE) {
                    alert('File size exceeds the limit of 25MB.');
                    event.target.value = null; // Clear the input field
                    return;
                } else {
                    newFiles.push(file);
                    newFileNames.push(file.name);
                }
            }

            setFileList(prevFiles => [...prevFiles, ...newFiles]);
            setUploadedFiles(prevNames => [...prevNames, ...newFileNames]);


        } catch (error) {
            setFinished(true)
            if (error.response?.data.error) {
                setError(error.response.data.error);
            } else if (error.message) {
                setError(error.message);
                //console.log(error.message)
            }
            else {
                setError("Error while uploading file.")
                //console.log(error)
            }
        }
    }

    const handleFileSelect = async (event) => {
        //console.log(event.target.files[0])
        try {

            const files = Array.from(event.target.files);
            const newFiles = [];
            const newFileNames = [];

            for (let file of files) {
                if (file.size > MAX_SIZE) {
                    alert('File size exceeds the limit of 25MB.');
                    event.target.value = null; // Clear the input field
                    return;
                } else {
                    newFiles.push(file);
                    newFileNames.push(file.name);
                }
            }

            setFileList(prevFiles => [...prevFiles, ...newFiles]);
            setUploadedFiles(prevNames => [...prevNames, ...newFileNames]);
            //await handleFileUpload(event.target.files[0]);
        } catch (error) {
            //console.log(error)
            setFinished(true)
            if (error.response) {
                setError(error.response.data.error);
            } else if (error.message) {
                setError(error.message);
                //console.log(error.message)
            }
            else {
                setError("Error while uploading file.")
                //console.log(error)
            }
        }
    };

    //delete uploaded file before submitting
    const handleUploadDelete = (fileName) => {
        //console.log(uploadedFiles)
        //console.log(fileName)
        //console.log("before", fileList)
        const index = uploadedFiles.findIndex((file) => file === fileName);
        setUploadedFiles(uploadedFiles.filter((file) => file !== fileName));
        //const test = fileList.filter((file) => file !== fileName)
        //console.log("after", fileList.filter((_, i) => i !== index))
        setFileList((prevArray) => {
            return prevArray.filter((_, i) => i !== index);
        });
    };

    const handleImport = async () => {
        try {
            if (!(ytUrl.startsWith('http://') || ytUrl.startsWith('https://'))) {
                alert("Please enter a valid URL (starting with 'http://)' or 'https://'.");
                setYtUrl("");
                return;
            }

            if (!ytUrl.includes("youtube.com") && !ytUrl.includes("youtu.be")) {
                alert("Please enter a valid YouTube URL.");
                setYtUrl("");
                return;
            } else {
                if (ytUrl.includes("youtube.com/shorts")) {
                    alert("We currently do not support YouTube Shorts.\nApologies for the inconvenience.");
                    setYtUrl("");
                    return;
                }

                setUrl(ytUrl)
            }
            //check if ytUrl is in urlList
            if (fileList.includes(ytUrl)) {
                setError("URL already uploaded.")
                setYtUrl("");
                return;
            }
            setFileList([...fileList, ytUrl]); setUploadedFiles([...uploadedFiles, ytUrl.replace(/^https?:\/\//i, '').replace(/\//g, '-').slice(0, 25)]);
            setYtUrl("");
        } catch (err) {
            setError("Error while importing URL.")
            //console.log(err)
        }
    };

    const sttHandler = async (e) => {
        e.preventDefault();
        try {
            setFinished(false);
            setGenerated("");
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                if (url.includes('youtube.com') || url.includes('youtu.be')) {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` }};
                    //console.log(url)
                    const {data} = await axios.post('/api/transcribe-yt', {url: url}, config);
                    console.log(data)
                    setGenerated(data);
                }   else {
                    const formData = new FormData();
                    //console.log(fileList[0])
                    formData.append('audio', fileList[0], 'audio.mp3');
                    const response = await axios.post('/api/stt', formData);
                    setGenerated(response.data)
                }
            }
            /*const formData = new FormData();
            formData.append('audio', fileList[0], 'audio.mp3');
            const response = await axios.post('/api/stt', formData);
            setGenerated(response.data)*/
            setUrl("");
            setFileList([]);
            setUploadedFiles([]);
            setFinished(true);
        } catch (err) {
            setUrl("");
            setFileList([]);
            setUploadedFiles([]);
            setFinished(true);
            //console.log(err)
            if (err?.response?.data?.error) {
                setError(err.response.data.error);
            } else if (err?.response?.data?.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
            
        }
    };

    return (
        <Box width={isNotMobile ? "50%" : "90%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "22vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={sttHandler}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center">Audio Transcriber</Typography>

                <Divider sx={{ mt:1, mb:2}}></Divider>
                
                {/*<button onClick={recorderControls.stopRecording}>Stop recording</button>*/}
                <Card onDragOver={(e) => e.preventDefault()} onClick={localStorage.getItem("authToken") && handleCardClick}
                    onDrop={localStorage.getItem("sub") && handleDrop}
                    sx={{boxShadow: 0, border: '1px dashed gray', background: 'transparent', px: 3, py:2, "&:hover": 
                    {cursor: "pointer"}, display: "flex", justifyContent: "center", bgcolor: mode === 'light' ? "#f9f9f9" : '#41577a', mb: 1}}>
                    <Stack width={"80%"} height="35vh" spacing={1} alignItems="Center" justifyContent="center">
                        {/*<img src={upload} alt="upload" width="8%" height="auto"/>*/}
                        <Typography variant={isNotMobile ? "h5" : "h6"} textAlign="center">Drag & Drop or <span style={{color: "blue", textDecoration: 'underline'}}>Choose File</span> to Upload MP3 or WAV<br/>(Max Size: 10MB)</Typography>
                    </Stack>
                </Card>
                <input
                    disabled={uploadedFiles.length >= MAX_UPLOADS }
                    type="file"
                    accept=".mp3,.wav"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    multiple
                />
                <Stack spacing={2} my={1} direction={isNotMobile ? "row" : "column"} overflow="auto">
                    {uploadedFiles.map((file) => (
                        <Chip key={file} label={file} value={file} style={{ margin: '4px' }} onDelete={() => handleUploadDelete(file)} />
                    ))}
                </Stack>
                <Typography textAlign="center" mb={1}>OR</Typography>
                <TextField fullWidth={true} size="small" placeholder="Insert YouTube URL (Max Length: 30min)" value={ytUrl} sx={{mb: 2, fontSize: 16}}
                        onChange={(e) => setYtUrl(e.target.value)}
                        disabled={uploadedFiles.length >= MAX_UPLOADS }
                        onKeyDown={(e)=>{if(e.key === 'Enter'){handleImport()}}}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment sx={{px:0}}>
                                <Button variant="contained" disableElevation 
                                onClick={()=>{handleImport()}} 
                                //onClick={()=>handleUrl()} 
                                disabled={ytUrl.length === 0 || uploadedFiles.length >= MAX_UPLOADS }
                                sx={{p:0, bgcolor: "#ebebeb", color: "black", '&:hover': {backgroundColor: "lightGray"}}}>
                                Import
                                </Button>
                            </InputAdornment>
                            ),
                        }}
                    />

                <ThemedButton w="100%" type="submit" text={btnText} fSize={isNotMobile ? 16 : 14} pad={isNotMobile ? 1.4 : 0.8} bool={!finished || fileList.length < 1}/>
                
                <Divider sx={{my: isNotMobile ? 4 : 2}}></Divider>

            </form>

            {!finished && !generated && <LoadingSpinner/>}

            { generated &&
            <Box width="100%">
                <Typography variant={isNotMobile ? "h4" : "h5"} fontWeight="bold"> Transcription </Typography>
                <Card sx={{mt: 2, mb:4, p:2, border: 1, boxShadow: 2, borderColor:"neutral.dark", height: 700, overflow: "auto", borderRadius: 0, bgcolor: "neutral.light",
                            "&::-webkit-scrollbar": {
                                width: 7,
                            },
                            "&::-webkit-scrollbar-track": {
                                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "darkgrey",
                                outline: `1px solid slategrey`,
                            },
                    }}>
                    <pre style={{ whiteSpace: "pre-wrap" }}><Typography ref={cardTextRef} fontSize={isNotMobile ? 16 : 14}>{generated.trimStart()}</Typography></pre>
                    <Divider/>
                    <Button onClick={handleCopyClick} disableElevation variant="contained" size="small" disabled={!finished}
                        sx={{'&:hover': {backgroundColor: "neutral.medium"}, fontWeight:"bold", borderColor:"#000", border:1, backgroundColor: "neutral.light", 
                                color:"black", textTransform: 'none', mt:2, fontSize: 12, borderRadius: 1, width: 110}}
                        startIcon={ <ContentCopyRoundedIcon/> }
                    >
                        Copy Text
                    </Button>
                </Card>

                
                
                {finished && 
                <Stack direction={isNotMobile ? "row" : "column"} alignItems="center"spacing={2} mb={2}>
                    <Button onClick={handleInsertText} variant="contained" size="medium" 
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, borderRadius: 1, width: 140}}
                            startIcon={ <ContentPasteGoRoundedIcon sx={{color: "white"}}/> }
                    >
                        Paste Text
                    </Button>
                   
                    <Button variant="contained" startIcon={<SaveIcon/>} 
                    sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                    onClick={()=>handleSave()}>
                        Save
                    </Button>
                    <Button variant="outlined" size="medium" onClick={handleShare}
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                            startIcon={ <ShareRoundedIcon sx={{color: "primary.main"}}/> }
                    >
                        Share
                    </Button>
                    <ShareModal
                        open={shareOpen}
                        content={htmlContent ? htmlContent : generated}
                        handleClose={handleShareClose}
                        mobile={!isNotMobile}
                        id={articleId}
                        kw={keyword}
                    />
                </Stack>
                }
                {audioUrl && <audio controls src={audioUrl}></audio>}

                {finished && <ReactQuill ref={quillRef} theme="snow" onChange={handleEditorChange}></ReactQuill>}
               
                <Stack direction={isNotMobile ? "row" : "column"} alignItems="center" spacing={2} mt={2}>
                    <Button variant="contained" onClick={() => { window.scrollTo({top: 0, behavior: "smooth"});}}
                        sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, borderRadius: 1, width: 140}}
                        startIcon={ <KeyboardDoubleArrowUpRoundedIcon/> }
                    > 
                        Back to top
                    </Button>

                    {finished && 
                    <Button variant="contained" startIcon={<SaveIcon/>} onClick={()=>handleSave()}
                        sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14}}>
                        Save
                    </Button>
                    }
                    {finished && 
                    <Button variant="outlined" size="medium" onClick={handleShare}
                            sx={{ fontWeight:"bold", textTransform: 'none', fontSize: 14, boxShadow: 2}}
                            startIcon={ <ShareRoundedIcon sx={{color: "primary.main"}}/> }
                    >
                        Share
                    </Button>
                    }
                    <ShareModal
                        open={shareOpen}
                        content={htmlContent ? htmlContent : generated}
                        handleClose={handleShareClose}
                        mobile={!isNotMobile}
                        id={articleId}
                        kw={keyword}
                    />
                </Stack>
            </Box>
            }
            
        </Box>
    )
}

export default SttScreen;