import React, {useState, useEffect} from 'react';
import { Box, Typography, Collapse, Alert, Button, Link, Stack, Tooltip, Divider, FormControlLabel,
  ClickAwayListener, IconButton, useMediaQuery, Switch } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./PricingScreen.css";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center",
      maxWidth: 210,
    },
}));

const PricingScreen = ({mode}) => {
  const isNotMobile = useMediaQuery("(min-width: 900px)");
  const [bgClass, setBgClass] = useState("pricing-column");
  const tooSmall = useMediaQuery("(max-width: 520px)");
  const [proPrice, setProPrice] = useState(350);
  const [enterprisePrice, setEnterprisePrice] = useState(3500);
  const navigate = useNavigate();

  const [openProAlert, setOpenProAlert] = useState(false);
  const [openProSubAlert, setOpenProSubAlert] = useState(false);//already have pro sub
  const [openFreeAlert, setOpenFreeAlert] = useState(false);//already have standard sub
  const [openPremiumAlert, setOpenPremiumAlert] = useState(false);
  const [perSub, setPerSub] = useState("/yr");
  const [checked, setChecked] = useState(true);

  const [openTooltips, setOpenTooltips] = useState({});

  // Function to handle opening a tooltip
  const handleTooltipOpen = (id) => {
    setOpenTooltips({ ...openTooltips, [id]: true });
  };

  // Function to handle closing a tooltip
  const handleTooltipClose = (id) => {
    setOpenTooltips({ ...openTooltips, [id]: false });
  };

  const handleSwitch = (event) => {
    setChecked(!checked);
    if (checked) {
      setPerSub("/mo");
      setProPrice(35);
      setEnterprisePrice(350);
    } else {
      setPerSub("/yr");
      setProPrice(350);
      setEnterprisePrice(3500);
    }
  };

  //useEffect for mode
  useEffect(() => {
    if (mode === 'light') {
      setBgClass("pricing-column");
    } else {
      setBgClass("pricing-column-dark");
    }
  }, [mode]);

  const freeHandler = async (e) => {
    e.preventDefault();
    try {
      if (!localStorage.getItem("authToken")) {
        setOpenFreeAlert(true);
        //close alert after 5 seconds
        setTimeout(() => {
            setOpenFreeAlert(false);
        }, 5000);
        return;
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
        console.log(error);
    }
  }

  const proHandler = async (e) => {
    e.preventDefault();
    try {
        if (!localStorage.getItem("authToken")) {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data}`
                },
            };
            const usr = await axios.get(`/api/auth/subscription`, config);
            const customerId = await axios.get(`/api/auth/customer`, config);

            if (usr.data.subscription) {
              if (customerId.data.customerId) {
                  const portal = await axios.post("/api/stripe/portal", { customerId: customerId.data.customerId }, config);
                  if (portal.data.url) {
                      window.open(portal.data.url, "_self");
                  }
              } else {
                if (checked)
                  navigate(`/checkout/pro-yearly`);
                else
                  navigate(`/checkout/pro`);
              }
          } else {
            if (checked)
              navigate(`/checkout/pro-yearly`);
            else
              navigate(`/checkout/pro`);
          }
        } else {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }

  const premiumHandler = async (e) => {
    e.preventDefault();
    try {
      if (!localStorage.getItem("authToken")) {
        setOpenFreeAlert(true);
        setTimeout(() => { setOpenFreeAlert(false); }, 5000);
        return;
      }
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data}`
                },
            };
            const usr = await axios.get(`/api/auth/subscription`, config);
            const customerId = await axios.get(`/api/auth/customer`, config);
            
            if (usr.data.subscription) {
                if (customerId.data.customerId) {
                    const portal = await axios.post("/api/stripe/portal", { customerId: customerId.data.customerId }, config);
                    if (portal.data.url) {
                        window.open(portal.data.url, "_self");
                    }
                } else {
                  if (checked)
                    navigate(`/checkout/enterprise-yearly`);
                  else
                    navigate(`/checkout/premium`);
                }
            } else {
              if (checked)
                navigate(`/checkout/enterprise-yearly`);
              else
                navigate(`/checkout/premium`);
            }
            
        } else {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }

  //useEffect to check cookies for ref
  /*useEffect(() => {
    if (Cookies.get('ref')) {
      setEnterprisePrice(300);
      setProPrice(25);
    }
  }, []);*/


    return (
      <Box sx={{letterSpacing: '0.5px'}}>
        <div class="landing-body">
        <div id="pricing" class="piearm-section">
            <Box mb={9} px={2}>
                <Typography variant="h1" align="center" color="textPrimary" gutterBottom fontWeight="bold"> Pricing </Typography>
                <div class={mode === 'light' ? "primary-subhead" : "primary-subhead-dark"}>
                  Get marketing copy fast!<br/>
                  Let PIEARM.AI do the heavy lifting for you. Get started with one of our affordable pricing plans now.
                </div>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={6}>
              <Stack direction="row" spacing={1} alignItems="center" bgcolor="#ebecf1" borderRadius={5} py={0.8} px={1.5}>
                <Box borderRadius={4} py={.5} px={1}
                sx={{bgcolor: checked ? "inherit" : "#fff", cursor: "pointer" }} onClick={handleSwitch}>
                  <Typography color={!checked ? "inherit" : "gray"} fontWeight="bold">Pay Monthly</Typography>
                </Box>
                <Stack borderRadius={4} py={.5} px={1} direction="row" alignItems="center" spacing={1}
                sx={{bgcolor: checked ? "#fff" : "inherit", cursor: "pointer" }} onClick={handleSwitch}>
                  <Typography color={checked ? "inherit" : "gray"} fontWeight="bold">Pay Yearly </Typography>
                  <Typography fontWeight={600} sx={{color: !checked ? "gray" : "#71a6d4", fontSize: 13}}>Save 16%</Typography>
                </Stack>
              </Stack>             
            </Box>
          <div class="container">
            <div class="inner-three-column">
              {/*<!-- FREE -->*/}
              <div class="pricing-column pricing-column-free" style={{backgroundColor: mode === 'dark' ? '#151920' : '#fafafa'}}>
                  <div class="content-block">
                    <div class="price-heading-wrap">
                      <div class="price-heading">Basic</div>
                      <Collapse in={openFreeAlert}>
                        <Alert severity='warning'>
                            Please <Link href="/login">login</Link> or <Link href="/register">register</Link> to continue.
                        </Alert>
                      </Collapse>
                    </div>
                    <div class="price-ammount"><span class="price-figure">$0</span><span class="price-suffix">{perSub}</span></div>
                    <div class="price-features">
                        <ul>
                          <li>Up to 2,500 generated words per month</li>
                          <li>1 User</li>
                        </ul>
                    </div>
                  </div>
                  <Button onClick={freeHandler} variant="outlined" fullWidth={true} disableElevation={true}
                        sx={{fontSize: 18, py:1.9, fontWeight: "bold",}}>
                           Sign Up for Free
                  </Button>
              </div>
      
              {/*<!-- PRO -->*/}
              <div class="pricing-column pricing-column-pro" style={{backgroundColor: mode === 'dark' ? '#151920' : '#fafafa'}}>
                  <div class="content-block">
                    <div class={mode === 'light' ? "popular-item" : 'popular-item-dark'}><span>Most Popular</span></div>
                    <div class="price-heading-wrap">
                      <div class="price-heading">Pro</div>
                      <Collapse in={openFreeAlert}>
                        <Alert severity='warning'>
                            Please <Link href="/login">login</Link> or <Link href="/register">register</Link> to continue.
                        </Alert>
                      </Collapse>
                      <Collapse in={openProAlert}>
                        <Alert severity='warning'>
                            You already have a pro subscription!
                        </Alert>
                      </Collapse>
                      <Collapse in={openProSubAlert}>
                        <Alert severity='warning'>
                            You already have a premium subscription! If you would like to downgrade, access your billing portal.
                        </Alert>
                      </Collapse>
                    </div>
                    <div class="price-ammount"><span class="price-figure">${proPrice}</span><span class="price-suffix">{perSub}</span></div>
                    <div class="price-features">
                      <ul>
                          <li>Up to 300,000 generated words per month</li>
                          <li>Generate up to 4 variants of ad and social media copy at one time</li>
                          <li>1 User</li>
                        </ul>
                    </div>
                  </div>
                  <Button onClick={proHandler} variant="contained" fullWidth={true} disableElevation={true}
                        sx={{fontSize: 18, py:1.9, fontWeight: "bold"}}>
                           {!localStorage.getItem("sub") ? "Upgrade" : localStorage.getItem("sub") === 'pro' ? "Billing Portal" : "Downgrade"}
                  </Button>
              </div>
      
              {/*<!-- Premium -->*/}
              <div class="pricing-column pricing-column-premium" style={{backgroundColor: mode === 'dark' ? '#151920' : '#fafafa'}}>
                  <div class="content-block">
                    <div class="price-heading-wrap">
                      <div class="price-heading">Enterprise</div>
                      <Collapse in={openFreeAlert}>
                        <Alert severity='warning'>
                            Please <Link href="/login">login</Link> or <Link href="/register">register</Link> to continue.
                        </Alert>
                      </Collapse>
                      <Collapse in={openPremiumAlert}>
                        <Alert severity='warning'>
                            You already have a premium subscription!
                        </Alert>
                      </Collapse>
                    </div>
                    <div class="price-ammount"><span class="price-figure">${enterprisePrice}</span><span class="price-suffix">{perSub}</span></div>
                    <div class="price-features">
                      <ul>
                          <li>All features included in the Pro Tier</li>
                          <li>Unlimited generated words per month</li>
                          <li>1 User</li>
                          <li>Priority customer support</li>
                          <li>Customized advanced reporting, analysis, and automated lead gen</li>
                        </ul>
                    </div>
                  </div>
                  <Button onClick={premiumHandler} variant="contained" fullWidth={true} disableElevation={true}
                        sx={{fontSize: 18, py:1.9, fontWeight: "bold"}}>
                           {!localStorage.getItem("sub") ? "Upgrade" : localStorage.getItem("sub") === 'pro' ? "Upgrade" : "Billing Portal"}
                  </Button>
              </div>
      
            </div> {/*<!-- End of inner-three-column -->*/}
            {!tooSmall && <Stack pb={8} spacing={2} mt='10vh'>
              <Typography variant={isNotMobile ? "h2" : "h4" } fontWeight={900} sx={{ textAlign: "center", mb: 4 }}>
                Compare features across plans
              </Typography>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 20 : 14} sx={{ flex: 1, textAlign: 'start', alignItems:'center', color: 'grey' }}>Features</Typography>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 20 : 14} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color="primary">Basic</Typography>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 20 : 14} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color="primary">Pro</Typography>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 20 : 14} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color="primary">Enterprise</Typography>
              </Stack>
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Word Limit
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip1')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip1')}
                    placement="right"
                    open={openTooltips['tip1'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Defines the maximum number of words you can generate each month.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip1')} onMouseEnter={() => handleTooltipOpen('tip1')}>
                                      <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>2,500 words</Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>300,000 words</Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>Unlimited words</Typography>
              </Stack>
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Platform Access
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip2')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip2')}
                    placement="right"
                    open={openTooltips['tip2'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Provides varying levels of access to platform features, from basic to full access.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip2')} onMouseEnter={() => handleTooltipOpen('tip2')}>
                                      <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'}} fontSize={isNotMobile ? 16 : 12}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>Basic</Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>Extended</Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', paddingRight: 8, fontSize: isNotMobile ? 20 : 14}}>✓</span>Full</Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Content Export
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip3')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip3')}
                    placement="right"
                    open={openTooltips['tip3'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Allows exporting of content to various formats or sharing directly via multiple channels.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip3')} onMouseEnter={() => handleTooltipOpen('tip3')}>
                                      <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Ask PIEARM
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip4')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip4')}
                    placement="right"
                    open={openTooltips['tip4'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='PIEARM Offers a customizable chat assistant for any inquiries.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip4')} onMouseEnter={() => handleTooltipOpen('tip4')}>
                          <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Digital Strategy
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip5')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip5')}
                    placement="right"
                    open={openTooltips['tip5'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Helps create detailed digital strategies for brands, companies, products, or services.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip5')} onMouseEnter={() => handleTooltipOpen('tip5')}>
                          <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Keyword Research & Content Planning
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip6')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip6')} placement="right"
                    open={openTooltips['tip6'] || false} disableFocusListener disableTouchListener
                    title='Provides tools for keyword research, understanding search intent, cost per click and content planning advice.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip6')} onMouseEnter={() => handleTooltipOpen('tip6')}>
                          <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Content Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip7')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip7')} placement="right"
                      open={openTooltips['tip7'] || false} disableFocusListener disableTouchListener
                      title='Generates automated optimized content for ad copy, social media, blogs, websites, email marketing, and more.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip7')} onMouseEnter={() => handleTooltipOpen('tip7')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  View Saved Copies
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip8')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip8')} placement="right"
                      open={openTooltips['tip8'] || false} disableFocusListener disableTouchListener
                      title='Allows access to view and manage saved and previously shared content stored in your personal profile.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip8')} onMouseEnter={() => handleTooltipOpen('tip8')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Social Media Integration
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip9')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip9')} placement="right"
                      open={openTooltips['tip9'] || false} disableFocusListener disableTouchListener
                      title='Integrates directly with various social media platforms for seamless content sharing.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip9')} onMouseEnter={() => handleTooltipOpen('tip9')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Social Media Variants Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip10')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip10')} placement="right"
                      open={openTooltips['tip10'] || false} disableFocusListener disableTouchListener
                      title='Generates multiple versions of social media content to test effectiveness.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip10')} onMouseEnter={() => handleTooltipOpen('tip10')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: 20, paddingRight: 8}}>✓</span> Up to 4 variations</Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: 20, paddingRight: 8}} >✓</span>Up to 4 variations</Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Audio Transcriber
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip11')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip11')} placement="right"
                      open={openTooltips['tip11'] || false} disableFocusListener disableTouchListener
                      title='Converts MP3 files, wave files, or YouTube videos up to 30 minutes into text.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip11')} onMouseEnter={() => handleTooltipOpen('tip11')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: isNotMobile ? 20 : 14}}>✓</span></Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Customer Support
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip12')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip12')} placement="right"
                      open={openTooltips['tip12'] || false} disableFocusListener disableTouchListener
                      title='Provides priority support for fast and personalized assistance to integrate Analytics and Reporting and AI Lead Generation.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip12')} onMouseEnter={() => handleTooltipOpen('tip12')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: 20, paddingRight: 8}}>✓</span> Priority Support</Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Analytics and Reporting
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip15')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip15')} placement="right"
                      open={openTooltips['tip15'] || false} disableFocusListener disableTouchListener
                      title="Offers custom Google Analytics KPIs integration and reports on content performance and user engagement."
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip15')} onMouseEnter={() => handleTooltipOpen('tip15')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: 20, paddingRight: 8}}>✓</span> Advanced Features</Typography>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  AI Lead Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip16')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip16')} placement="right"
                      open={openTooltips['tip16'] || false} disableFocusListener disableTouchListener
                      title="Collects up to 20 legally compliant data points about your website visitors. Collect up to 100 leads a month. Additional leads are available at $1.00 per lead."
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip16')} onMouseEnter={() => handleTooltipOpen('tip16')}>
                            <HelpIcon sx={{color: "primary.main", fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Typography fontSize={isNotMobile ? 16 : 12} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}><span style={{color:'blue', fontSize: 20, paddingRight: 8}}>✓</span> Advanced Features</Typography>
              </Stack> 
              <Divider/>
            </Stack> }
          </div>
        </div>
      </div>
      </Box>
    )
}

export default PricingScreen;