import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Stack, Divider, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom';
import ThemedButton from '../common/ThemedButton';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';

const RegisterScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const config = {
        headers: { "Content-Type": "application/json" },
    }

    const registerHandler = async (e) => { 
        e.preventDefault();

        try {
            await axios.post("/api/auth/register", {email, password}, config);
            navigate("/login");

        } catch (err) {
            console.log(err);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.message) {
                setError(err.message);
            }
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    }

    const [ user, setUser ] = useState("");
    const googleRegister = useGoogleLogin({
        onSuccess: (codeResponse) => {setUser(codeResponse.access_token); console.log(codeResponse);},
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                console.log(user)
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user}`, {
                        headers: {
                            Authorization: `Bearer ${user}`,
                            Accept: 'application/json'
                        }
                    })
                    .then(async (res) => {
                        const name = res.data.name;
                        const google_email = res.data.email;
                        const verified_email = res.data.verified_email;
                        const { data } = await axios.post("/api/auth/google-register", {name, google_email, verified_email}, config);
                        console.log(data)
                        if (data.token.accessToken) {
                            localStorage.setItem("authToken", true);
                            navigate("/");
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        if (err.response.data.error) {
                            setError(err.response.data.error);
                            setTimeout(() => {setError("");}, 4000);
                        } else {
                            setError(err.message);
                            setTimeout(() => {setError("");} , 4000);
                        }
                    });
            }
        },
        [ user ]
    );

    return (
        <Box width={isNotMobile ? "30%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            mt={isNotMobile ? 16 : 14}
            mb={isNotMobile ? 14 : 18}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={registerHandler}>
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center">Sign Up</Typography>

                <Button variant="outlined" size="large" fullWidth sx={{py: 1.3}}
                    startIcon={<GoogleIcon/>}
                    onClick={() => googleRegister()}
                >
                    Sign up with Google
                </Button>

                <Divider sx={{mt:3, mb:2}}> <Typography fontWeight="bold" color="grey">OR</Typography> </Divider>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:2}} required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Typography variant="h6" fontWeight={500} mb={1}>Password</Typography>
                <TextField type="password" sx={{".MuiOutlinedInput-root": { boxShadow: 1 }, mb:4}} required fullWidth value={password} onChange={(e) => setPassword(e.target.value)}/>

                <ThemedButton w="100%" type="submit" text="Sign Up"/>
                
                <Divider sx={{my:4}}></Divider>

            </form>


            <Typography textAlign="center">Already have an account? <ReactRouterLink style={{color: theme.palette.primary.main}} to="/login"><b>Sign in</b></ReactRouterLink></Typography>
        </Box>
    )
}

export default RegisterScreen;